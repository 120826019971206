import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PolicyType } from 'api/schema/insured.schema';
import { useFormikContext } from 'formik';
import { parseAddress } from 'helpers/address';
import { getPageByPath, Tabs } from 'helpers/pages';
import { getIsProperty } from 'helpers/policy-and-residence';
import { useDriverDlInfo } from 'hooks/useDrivers';
import {
  useAddedUsingVinVehicles,
  useBusinessVehicles,
  useVehicleCompanyInfo,
} from 'hooks/useVehicles';
import { omitBy, range, uniq } from 'lodash';
import { ClubContext } from 'components/contexts/ClubContext';
import { DiscountContext } from 'components/contexts/DiscountContext';
import { DriverContext } from 'components/contexts/DriverContext';
import { InsuredContext } from 'components/contexts/InsuredContext';
import { VehicleContext } from 'components/contexts/VehicleContext';
import {
  DriverDescription,
  FormValues,
  VehicleDescription,
} from 'components/forms/formTypes';

export interface MenuData {
  visitedTabs: Tabs[];
  product?: PolicyType;
  phone?: string;
  about?: {
    primary: string;
    address: string;
  };
  coverage?: {
    auto?: {
      vehicles: string;
      drivers: string;
    };
    property?: {
      address: string;
    };
  };
  businessVehiclesList: VehicleDescription[];
  incompleteVehicleCompanyInfo: VehicleDescription[] | undefined;
  vinVehiclesList: VehicleDescription[];
  incompleteDriverDlInfo: DriverDescription[] | undefined;
}

export const useMenuData = (): MenuData => {
  const { values } = useFormikContext<FormValues>();
  const { getVehiclesById } = useContext(VehicleContext);
  const { getDriversById } = useContext(DriverContext);
  const { clubState } = useContext(ClubContext);
  const [visitedTabs, setVisitedTabs] = useState<Tabs[]>([]);
  const [{ fetched: isInsuredFetched, insured }] = useContext(InsuredContext);
  const {
    vehicleContextState: { fetched: areVehiclesFetched },
  } = useContext(VehicleContext);
  const {
    driverContextState: { fetched: areDriversFetched },
  } = useContext(DriverContext);
  const {
    discountContextState: { fetched: areDiscountsFetched },
  } = useContext(DiscountContext);
  const history = useHistory();
  // purchase
  const [incompleteVehicleCompanyInfo] = useVehicleCompanyInfo();
  const [businessVehiclesList] = useBusinessVehicles();
  const [vinVehiclesList] = useAddedUsingVinVehicles();
  const [incompleteDriverDlInfo] = useDriverDlInfo();

  // This is needed to show menus under tabs only after a certain page was visited
  useEffect(() => {
    const tabIndex = getPageByPath(history.location.pathname)?.tabIndex;
    const aboutYou = isInsuredFetched && Tabs.AboutYou;
    const discounts = areDiscountsFetched && Tabs.Discounts;
    const whatsCovered =
      (insured?.product === PolicyType.AUTO
        ? areDriversFetched
        : insured?.address) && Tabs.WhatsCovered;
    const priorTabs = tabIndex === false ? [] : range(tabIndex || 0);
    const newVisitedTabs = uniq([
      ...priorTabs,
      ...visitedTabs,
      tabIndex,
      aboutYou,
      whatsCovered,
      discounts,
    ]).filter((v) => v !== false && v !== undefined);
    setVisitedTabs(newVisitedTabs as Tabs[]);
  }, [
    isInsuredFetched,
    areVehiclesFetched,
    areDriversFetched,
    areDiscountsFetched,
    history.location.pathname,
  ]);

  // Phone
  const phone = clubState.formatedPhonenNumber && clubState.supportPhoneNo;

  // Tell Us Abot You
  const primary = `${values.firstName} ${values.lastName}`.trim();
  const address = parseAddress(values.address).addressLine1;
  const about =
    (isInsuredFetched && primary && address && { primary, address }) ||
    undefined;

  // What's Covered
  const product =
    insured?.product || (values.zipcode && values.product) || undefined;

  const isAuto = product === PolicyType.AUTO;
  const isProperty = getIsProperty(product);

  const vehicleDescriptions = getVehiclesById(values.vehicleIds);

  const makes = vehicleDescriptions
    .filter(({ make }) => make)
    .map(({ make }) => make!);

  const firstTwoMakes = makes.slice(0, 2).join(', ');
  const otherMakesCount = makes.slice(2).length || '';

  const otherMakes = otherMakesCount && ` (+${otherMakesCount})`;
  const vehicles = firstTwoMakes + otherMakes;

  const driverDescriptions = getDriversById(values.driverIds);

  const names = driverDescriptions
    .filter(({ firstName }) => firstName)
    .map(({ firstName }) => firstName!);

  const firstTwoNames = names.slice(0, 2).join(', ');
  const otherNamesCount = names.slice(2).length || '';

  const otherNames = otherNamesCount && ` (+${otherNamesCount})`;
  const drivers = firstTwoNames + otherNames;

  const autoCoverage = isAuto &&
    vehicles &&
    drivers && {
      auto: {
        vehicles,
        drivers,
      },
    };

  const propertyCoverage = isProperty && address && { property: { address } };
  const coverage = autoCoverage || propertyCoverage || undefined;

  const menuData: MenuData = {
    visitedTabs: visitedTabs,
    product,
    phone,
    about,
    coverage,
    businessVehiclesList,
    incompleteVehicleCompanyInfo,
    vinVehiclesList,
    incompleteDriverDlInfo,
  };

  return omitBy(menuData, (v) => !v) as MenuData;
};
