import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';
import { getNameByPath } from 'helpers/pages';
import useSegment from 'hooks/useSegment';
import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';
import { ClubContext } from 'components/contexts/ClubContext';

export const usePhoneNumberLinkStyles = makeStyles()(() => ({
  // TODO: Think of ways of enforcing this style for scenarios,
  // where multiple classes with equal specificity need to be used
  // without the use of !important
  phoneLink: {
    color: `${colors.linkBlue}!important`,
    textDecoration: 'none!important',
  },
}));

export const PhoneNumberLink = (props: {
  formattedPhoneNumber?: string;
  phoneNumber?: string;
  location?: string;
}) => {
  const { formattedPhoneNumber, location } = props;
  const { classes } = usePhoneNumberLinkStyles();
  const {
    clubState: { formatedPhonenNumber },
  } = useContext(ClubContext);
  const { track } = useSegment();
  const history = useHistory();
  const { t } = useTranslation();
  const isPurchaseConfirmation =
    getNameByPath(history.location.pathname) === 'PurchaseConfirmation';
  const staticPhoneNumber =
    isPurchaseConfirmation && t('purchaseConfirmationPage.phno');

  const phNumber =
    staticPhoneNumber || formattedPhoneNumber || formatedPhonenNumber;
  return (
    <Link
      href={`tel:${phNumber}`}
      className={classes.phoneLink}
      onClick={() => {
        track('Phone Number Accessed', {
          phone_number_accessed: phNumber,
          location,
          eventType: 'Link Accessed',
        });
      }}
      aria-label="Call Agent"
      underline="hover"
    >
      {phNumber}
    </Link>
  );
};
