import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { PolicyType } from 'api/schema/insured.schema';
import { getCookie } from 'helpers/cookies';
import { useDeviceTypes } from 'helpers/devices';
import { useMenuData } from 'hooks/useMenuData';
import useSegment from 'hooks/useSegment';
import { useStartOver } from 'hooks/useStartOver';
import { makeStyles } from 'tss-react/mui';
import { ClubContext } from 'components/contexts/ClubContext';
import { InsuredContext } from 'components/contexts/InsuredContext';
import { QuoteContext } from 'components/contexts/QuoteContext';
import { PhoneNumberLink } from '../components/common/PhoneNumberLink';
import {
  getIndexByName,
  getIndexByPath,
  getNameByPath,
  getPageByPath,
  getPathByName,
  getTabIndexByPath,
  PageName,
  Tabs as ETabs,
} from '../helpers/pages';
import { ReactComponent as AAALogo } from '../images/icons/logo_aaa.svg';
import { colors, getZIndexForPreference } from './../styles/theme';
import Button from './common/Button';
import SaveQuoteModalForm from './modals/SaveQuoteModal';
import { NavigationMenu } from './NavigationMenu';

interface A11yProps {
  id: string;
  'aria-controls': string;
}

const navMobileHeight = '56px';
const navHeight = '120px';

export const getPathByTabIndex = (
  index: number,
  product: PolicyType = PolicyType.AUTO,
  isBindEnabled?: boolean,
): string =>
  [
    getPathByName('TellUsAboutYou'),
    product === PolicyType.AUTO
      ? getPathByName('SelectVehicles')
      : getPathByName('DwellingUsage'),
    getPathByName('DiscountTransition'),
    getPathByName('Quote'),
    isBindEnabled
      ? getPathByName('EffectiveDate')
      : getPathByName('Confirmation'),
  ][index] || '/';

export const useAccessLink = () => {
  const history = useHistory();
  const { track } = useSegment();

  return (pageName: PageName | '', params?: string) => {
    const path = `${getPathByName(pageName || 'Entry')}${params ? params : ''}`;

    track('Link Accessed', {
      link: pageName,
      link_placement: 'navigation menu',
      link_destination: path,
      eventType: 'Link Accessed',
    });

    history.push(path);
  };
};

const styles = makeStyles()(({ palette, breakpoints }: Theme) => ({
  root: {
    background: colors.transparentWhite,
    backdropFilter: 'blur(8px)',
    zIndex: getZIndexForPreference(2),
    height: navHeight,
    minHeight: navHeight,
    boxShadow: 'none',
    [breakpoints.down('desktop')]: {
      height: navMobileHeight,
      minHeight: navMobileHeight,
    },
  },
  toolBarRoot: {
    height: '100%',
    [breakpoints.down('desktop')]: {
      padding: 0,
      margin: 0,
      minHeight: navMobileHeight,
    },
  },
  tabsRoot: {
    height: '100%',
    width: '100%',
  },
  tabRoot: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.grey100,
    height: '100%',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: 700,
    opacity: 1,
    '&:hover': {
      backgroundColor: colors.grey5,
    },
    '&.Mui-selected': {
      fontWeight: '700 !important',
      color: colors.primaryBlue50,
    },
    '&.Mui-disabled': {
      fontWeight: '700 !important',
      color: colors.grey60,
      opacity: 1,
    },
    '& .MuiTab-wrapper': {
      '& svg': {
        width: '18px',
        height: '18px',
        marginBottom: '0!important',
        marginLeft: '16px',
      },
    },
  },
  tabIndicator: {
    backgroundColor: palette.text.primary,
    height: '0',
  },
  logoContainer: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    [breakpoints.down('desktop')]: {
      alignItems: 'left',
      justifyContent: 'left',
    },
  },
  logo: {
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outlineOffset: '5px',
    },
    [breakpoints.down('desktop')]: {
      height: '28px',
    },
  },
  menuIconContainer: {
    width: 'auto',
    margin: 0,
    '&:focus': {
      outlineOffset: '5px',
    },
  },
  menuIcon: {},
  phoneLink: {
    color: colors.linkBlue,
    textDecoration: 'none',
  },
  agentNumber: {
    fontSize: '18px',
    fontWeight: 700,
    color: colors.grey60,
    [breakpoints.down('desktop')]: {
      fontSize: '16px',
    },
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    [breakpoints.up('tablet')]: {
      alignSelf: 'flex-start',
    },
    [breakpoints.only('tablet')]: {
      paddingBottom: '8px',
    },
    [breakpoints.only('mobile')]: {
      textAlign: 'left',
    },
  },
  saveQuoteButton: {
    [breakpoints.up('desktop')]: {
      margin: '0 35px',
    },
    [breakpoints.down('desktop')]: {
      width: '100%',
      margin: '12px auto',
    },
  },
  saveQuoteContainer: {
    [breakpoints.up('desktop')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      opacity: 1,
    },
  },
}));
const a11yProps = (index: number): A11yProps => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': 'navigation-menu',
  };
};

export type MenuStylesProps = {
  isDesktop: boolean;
};

const NavBar = () => {
  const { isDesktop } = useDeviceTypes();
  const { classes } = styles();
  const { t } = useTranslation();
  const location = useLocation();
  const { clubState } = useContext(ClubContext);
  const { quoteElementState, setQuoteElementState, quoteState } =
    useContext(QuoteContext);
  const [{ bindEnabled: isBindEnabled }] = useContext(InsuredContext);
  const [isPurchaseMenuEnabled, setPurchaseMenuEnabled] =
    useState<boolean>(false);

  const tabs = [
    t('navigation.aboutYou'),
    t('navigation.whatsCovered'),
    t('navigation.discounts'),
    t('navigation.quote'),
  ];

  const currentPage = getPageByPath(location.pathname);
  const isPageInBindFlow =
    isBindEnabled && currentPage.tabIndex === ETabs.Purchase;

  (isPageInBindFlow || isPurchaseMenuEnabled) &&
    tabs.push(t('navigation.purchase'));

  const { startOver } = useStartOver();
  const [currentTabIndex, setCurrentTabIndex] = useState<number | boolean>(
    false,
  );

  const [isReviewCoveragesEnabled, setReviewCoveragesEnabled] =
    useState<boolean>(false);
  const data = useMenuData();
  const { track } = useSegment();

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const pageLayout = document?.querySelector('[data-testid="root"]');
    if (pageLayout) {
      pageLayout.addEventListener('mouseenter', () => handleClose());
      pageLayout.addEventListener('click', () => handleClose());
    }
  }, []);

  useEffect(() => {
    handleClose();
    const tabIndex = getTabIndexByPath(location.pathname);
    setCurrentTabIndex(tabIndex);

    if (isPageInBindFlow) {
      setPurchaseMenuEnabled(true);

      getIndexByPath(location.pathname) >= getIndexByName('PaymentPlans') &&
        setReviewCoveragesEnabled(true);
    }
  }, [
    location.pathname,
    isBindEnabled,
    quoteState?.quoteInfo,
    isPageInBindFlow,
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoverTab, setHoverTab] = useState<ETabs>(ETabs.AboutYou);

  const handleLogoClick = () => {
    track('Link Accessed', {
      link: 'AAA Logo',
      link_placement: 'navigation menu',
      link_destination: 'Entry',
      eventType: 'Link Accessed',
    });
    startOver();
  };

  const accessLink = useAccessLink();
  const isPurchaseConfirmation =
    getNameByPath(location.pathname) === 'PurchaseConfirmation';
  const handleHover =
    (hasMenu: boolean, i: number) =>
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      const anchor = hasMenu ? event.currentTarget : null;
      setAnchorEl(anchor);
      hasMenu && setHoverTab(i);
    };

  return (
    <>
      <NavigationMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        hoverTab={hoverTab}
        data={data}
        currentTabIndex={currentTabIndex}
        isPurchaseMenuEnabled={isPurchaseMenuEnabled}
        isReviewCoveragesEnabled={isReviewCoveragesEnabled}
      />
      <AppBar className={classes.root} position="fixed">
        <Toolbar className={classes.toolBarRoot}>
          <Grid container alignItems="stretch" style={{ height: '100%' }}>
            <Grid className={classes.logoContainer} mobile={4} tablet={1} item>
              {!isDesktop && !isPurchaseConfirmation && (
                <IconButton
                  className={classes.menuIconContainer}
                  disableTouchRipple
                  disableFocusRipple
                  aria-label="menu"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  size="large"
                >
                  <MenuIcon className={classes.menuIcon} />
                </IconButton>
              )}
              <AAALogo
                className={classes.logo}
                onClick={handleLogoClick}
                role="button"
                data-testid="logo"
                aria-label="Logo"
                tabIndex={0}
                onKeyDown={(e: React.KeyboardEvent) => {
                  e.key === 'Enter' && handleLogoClick();
                }}
                title="Logo"
              />
            </Grid>
            {isDesktop ? (
              <Grid tablet={11} item>
                <Grid
                  container
                  justifyContent={'center'}
                  style={{ height: '100%' }}
                >
                  {!isPurchaseConfirmation && (
                    <Tabs
                      aria-label="CSAA Quote Navigation Tabs"
                      classes={{
                        flexContainer: classes.tabsRoot,
                        indicator: classes.tabIndicator,
                        root: classes.tabsRoot,
                      }}
                      onChange={(_, i) => {
                        const path = getPathByTabIndex(
                          i,
                          data.product,
                          isBindEnabled,
                        );
                        accessLink(getNameByPath(path));
                      }}
                      value={currentTabIndex}
                      variant="fullWidth"
                    >
                      {tabs.map((tab, i) => {
                        const isExpanded = hoverTab === i && !!anchorEl;
                        const isCurrentTab = currentTabIndex === i;
                        const purchaseTab = ETabs.Purchase;
                        const noMenuTabs = [
                          ETabs.Discounts,
                          ETabs.Quote,
                          ETabs.Quote,
                        ];
                        const hasMenu =
                          (data.visitedTabs.includes(i + 1) ||
                            (data.visitedTabs.includes(purchaseTab) &&
                              isPurchaseMenuEnabled)) &&
                          !noMenuTabs.includes(i);
                        const ExpandIcon = (isExpanded && <ExpandLess />) || (
                          <ExpandMore />
                        );
                        const isDisabled =
                          (!data.visitedTabs.includes(i) && !isCurrentTab) ||
                          Number(currentTabIndex) < i;
                        const icon =
                          hasMenu && !isDisabled ? ExpandIcon : undefined;
                        return (
                          <Tab
                            aria-haspopup={hasMenu}
                            className={classes.tabRoot}
                            key={tab}
                            icon={icon}
                            label={tab}
                            tabIndex={0}
                            disabled={isDisabled}
                            value={i}
                            onMouseEnter={handleHover(hasMenu, i)}
                            onKeyDown={(e) => {
                              e.key === 'ArrowDown' &&
                                handleHover(hasMenu, i)(e);
                            }}
                            {...a11yProps(i)}
                          />
                        );
                      })}

                      {getCookie('featureFlags')?.SAVE_QUOTE_FEATURE &&
                        isDesktop &&
                        [ETabs.WhatsCovered, ETabs.Discounts].includes(
                          currentTabIndex as ETabs,
                        ) && (
                          <Box className={classes.saveQuoteContainer}>
                            <Button
                              fullWidth={false}
                              color="secondary"
                              csaaType="secondary"
                              variant={isDesktop ? 'contained' : 'text'}
                              className={classes.saveQuoteButton}
                              onClick={() =>
                                setQuoteElementState({
                                  ...quoteElementState,
                                  saveQuoteModalOpen: true,
                                })
                              }
                            >
                              {t('savequotemodal.button')}
                            </Button>
                          </Box>
                        )}
                    </Tabs>
                  )}
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  mobile={1}
                  tablet={8}
                  style={{ visibility: 'hidden' }}
                />
                <Grid
                  item
                  mobile={7}
                  tablet={3}
                  className={classes.phoneContainer}
                >
                  <Typography
                    className={classes.agentNumber}
                    data-testid="footer-agent-number"
                  >
                    {clubState.supportPhoneNo && (
                      <>
                        {t('footer.help')} <PhoneNumberLink />
                      </>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
        {getCookie('featureFlags')?.SAVE_QUOTE_FEATURE &&
          quoteElementState.saveQuoteModalOpen && (
            <SaveQuoteModalForm currentTabIndex={currentTabIndex as ETabs} />
          )}
      </AppBar>
    </>
  );
};

export default NavBar;
