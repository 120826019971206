import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ThirdPartyAddress,
  Vehicle,
} from '@aaa-ncnu-ie/ez-quote-session-types';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest, { DEFAULT_AGENT_ERROR_CODES } from 'api/makeRequest';
import { PolicyType } from 'api/schema/insured.schema';
import {
  AddVehicleResponse,
  AntitheftLabelMap,
  GetAntitheftMetadata,
  GetLessorLienholdersResponse,
  GetVehiclesResponse,
  UpdateBusinessUsageRequest,
  VehicleAdditionalInfo,
  VehicleAddress,
  VehicleMileageEstimation,
  VehiclePrimaryInfo,
  VinInfo,
} from 'api/schema/vehicle.schema';
import { OnHydrated } from 'helpers/form';
import {
  VehicleDescription,
  VehicleOwnership,
  VehicleUsageType,
} from 'components/forms/formTypes';
import { ClubContext } from './ClubContext';
import { InsuredContext } from './InsuredContext';

export interface VehicleContextState {
  vehicles: VehicleDescription[];
  fetched: boolean;
  vehiclesVisited: string[];
  antitheftMetadata: GetAntitheftMetadata;
  vehiclesAddedManually: string[];
  address: VehicleAddress | undefined;
  vehicleLienholdersMetadata: GetLessorLienholdersResponse | undefined;
  vehicleLessorMetadata: GetLessorLienholdersResponse | undefined;
  showMileageFields?: boolean;
  noRideshare?: boolean;
  defaultAnnualMiles?: number;
  defaultMilesOneWay?: number;
}

export type VehicleContextType = {
  vehicleContextState: VehicleContextState;
  fetchAllVehicles: () => Promise<VehicleDescription[] | undefined>;
  fetchAllVehiclesAddress: () => Promise<VehicleAddress | undefined>;
  createVehicleByVin: (vin: string) => Promise<AddVehicleResponse | undefined>;
  createVehicle: (vehicle: {
    year: string;
    make: string;
    model: string;
    series: string;
    bodyStyle: string;
    vehIdentificationNo: string;
    vin?: string;
  }) => Promise<AddVehicleResponse | undefined>;
  updateVehicle: (
    vehicle: Partial<VehicleDescription>,
  ) => Promise<VehicleDescription[] | undefined>;
  selectVehicles: (
    vehicleIds: string[],
  ) => Promise<VehicleDescription[] | undefined>;
  getVehiclesById: (vehicleIds: string[]) => VehicleDescription[];
  getVehicleIndex: (vehicleId?: string | null) => number;
  getVehicleMileage: (
    milesToWork: string,
    vehicleId: string,
    vehicleUsage: VehicleUsageType,
  ) => Promise<VehicleMileageEstimation | undefined>;
  getVehicleLienholders: (
    ownership: VehicleOwnership,
    productCd: ProductCd,
  ) => void;
  updateVehiclesVisited: (
    vehicleIds: string[],
    updatedVehicles: VehicleDescription[] | undefined,
  ) => void;
  updateVehicleCompanyInfoFlag: (
    vehicleIds: string,
    skipped: boolean,
    reset?: boolean,
    thirdPartyAddress?: ThirdPartyAddress | undefined,
  ) => void;
  updateBusinessUsage: (
    vehicleIds: string[],
  ) => Promise<VehicleDescription[] | undefined>;
  updateVehicleVin: (body: any) => void;
};

export enum ProductCd {
  Choice = 'AAA_CSA',
  Signature = 'AAA_SS',
}

const defaultVehicleContextState: VehicleContextState = {
  vehicles: [],
  vehiclesVisited: [],
  vehiclesAddedManually: [],
  fetched: false,
  antitheftMetadata: { items: [] as unknown as AntitheftLabelMap },
  address: {},
  vehicleLienholdersMetadata: undefined,
  vehicleLessorMetadata: undefined,
  showMileageFields: false,
  noRideshare: false,
  defaultAnnualMiles: undefined,
  defaultMilesOneWay: undefined,
};

export const VehicleContext = createContext<VehicleContextType>({
  vehicleContextState: defaultVehicleContextState,
  fetchAllVehicles: () => Promise.resolve(undefined),
  fetchAllVehiclesAddress: () => Promise.resolve(undefined),
  createVehicleByVin: () => Promise.resolve(undefined),
  createVehicle: () => Promise.resolve(undefined),
  updateVehicle: () => Promise.resolve(undefined),
  selectVehicles: () => Promise.resolve(undefined),
  getVehiclesById: () => [],
  getVehicleIndex: () => -1,
  updateVehiclesVisited: () => undefined,
  updateVehicleCompanyInfoFlag: () => undefined,
  updateBusinessUsage: () => Promise.resolve(undefined),
  updateVehicleVin: () => undefined,
  getVehicleMileage: () => Promise.resolve(undefined),
  getVehicleLienholders: () => Promise.resolve(undefined),
});

const VehicleContextProvider: React.FC<ContainerProps> = (props) => {
  const [vehicleContextState, setVehicleContextState] = useState(
    defaultVehicleContextState,
  );
  const [antitheftMetadata, setAntitheftMetadata] =
    useState<GetAntitheftMetadata>({
      items: [] as unknown as AntitheftLabelMap,
    });
  const { clubState } = useContext(ClubContext);
  const [insuredContextState] = useContext(InsuredContext);
  const makeRequest = useRequest();
  const {
    actionCreators: {
      getAllVehicles,
      createVehicle,
      getVehicleByVin,
      updateVehicle,
      selectVehicles,
      getAntitheftMetadata,
      getAllVehicleAddress,
      updateBusinessUsage,
      updateVehicleVin,
      getVehicleMileage,
      getVehicleLienholders,
    },
  } = useActionCreators();

  // Hydration on Refresh
  useEffect(() => {
    // It is only possible to get vehicles on page refresh if and only if
    // user got past the address page and the "primary-insured"
    // object was created
    const isAuto = insuredContextState.insured?.product === PolicyType.AUTO;
    if (insuredContextState.fetched && isAuto) {
      handleFetchAllVehicles();
    }
  }, [insuredContextState.fetched, insuredContextState.insured?.product]);

  // Hydration on Refresh
  useEffect(() => {
    // This is important to hydrate selected vehicles
    // at this stage because it is required for the navigation
    // menu to be populated
    if (vehicleContextState.vehicles) {
      const vehicleIds = vehicleContextState.vehicles
        .filter(({ selected, vehicleId }) => selected && vehicleId)
        .map(({ vehicleId }) => vehicleId!);
      OnHydrated.publish({
        vehicleIds,
      });
    }
  }, [vehicleContextState.fetched]);

  useEffect(() => {
    const isAuto = insuredContextState.insured?.product === PolicyType.AUTO;
    const isAntitheftEnabled = ['NJ', 'NY'].includes(clubState.state);
    if (insuredContextState.fetched && isAuto && isAntitheftEnabled) {
      handleGetAntitheftMetadata();
    }
  }, [
    insuredContextState.fetched,
    insuredContextState.insured?.product,
    clubState.state,
  ]);

  const handleFetchAllVehicles = (
    state?: typeof vehicleContextState,
  ): Promise<VehicleDescription[] | undefined> => {
    return makeRequest(getAllVehicles).then((vehiclesResponse) => {
      if (!vehiclesResponse) return Promise.resolve(undefined);
      const currentState = state ?? vehicleContextState;
      const vehicles: VehicleDescription[] = vehiclesResponse.vehicles
        .map(
          (vehicle): VehicleDescription =>
            mapVehicleResponseToVehicleDescription(vehicle),
        )
        // Rideshare information is currently not persisted on the back-end
        // this is why it gets overriden every time we navigate back in the form.
        // This will help to keep this in the non-refresh flow.
        .map((v) => ({
          ...currentState?.vehicles.find(
            ({ vehicleId }) => vehicleId === v.vehicleId,
          ),
          ...v,
        }));

      setVehicleContextState((prevState) => ({
        ...prevState,
        ...(state && { vehiclesAddedManually: state.vehiclesAddedManually }),
        vehicles,
        antitheftMetadata,
        fetched: true,
        showMileageFields: vehiclesResponse.showMileageFields,
        noRideshare: vehiclesResponse.noRideshare,
        defaultAnnualMiles: vehiclesResponse.defaultAnnualMiles,
        defaultMilesOneWay: vehiclesResponse.defaultMilesOneWay,
      }));
      return vehicles;
    });
  };

  const handleFetchAllVehiclesAddress = (): Promise<
    VehicleAddress | undefined
  > => {
    return makeRequest(getAllVehicleAddress).then(
      (vehiclesResponse: VehicleAddress | undefined) => {
        if (!vehiclesResponse) return Promise.resolve(undefined);
        setVehicleContextState((prevState) => ({
          ...prevState,
          address: vehiclesResponse,
        }));
        return vehiclesResponse;
      },
    );
  };

  const handleGetAntitheftMetadata = (): Promise<
    GetAntitheftMetadata | undefined
  > => {
    return makeRequest(() =>
      getAntitheftMetadata({
        state: clubState.state,
      }),
    ).then((antitheftResponse: GetAntitheftMetadata | undefined) => {
      if (!antitheftResponse) return Promise.resolve(undefined);
      setAntitheftMetadata(antitheftResponse);
      setVehicleContextState((prevState) => ({
        // This is important to hydrate metadata on refresh
        ...prevState,
        antitheftMetadata: antitheftResponse,
      }));
      return antitheftResponse;
    });
  };

  const handleCreateVehicleByVin = async (
    vin: string,
  ): Promise<AddVehicleResponse | undefined> => {
    //Get Vehicle info based in vin provided by user
    const vehicleInfo: VinInfo | undefined = await makeRequest(
      () => getVehicleByVin({ vin, state: clubState.state }),
      undefined,
      false,
    );
    // Return early if we were not able to fetch the vehicle info
    if (!vehicleInfo) return Promise.resolve(undefined);

    const vehicleToCreate = { ...vehicleInfo, vehIdentificationNo: vin };
    return handleCreateVehicle(vehicleToCreate);
  };

  const handleGetVehicleMileage = async (
    milesToWork: string,
    vehicleId: string,
    vehicleUsage: VehicleUsageType,
  ): Promise<VehicleMileageEstimation | undefined> => {
    //Get Vehicle mileage based on
    const vehicleInfo: VehicleMileageEstimation | undefined = await makeRequest(
      () =>
        getVehicleMileage({
          milesToWork,
          vehicleId,
          autoQuoteSource: 'EZQuote',
          vehicleUsage,
        }),
      undefined,
      false,
    );
    return vehicleInfo;
  };

  const handleCreateVehicle = async (vehicle: {
    year: string | number;
    make: string;
    model: string;
    series: string;
    bodyStyle: string;
    vehIdentificationNo: string;
    vin?: string;
  }): Promise<AddVehicleResponse | undefined> => {
    // Make request to create vehicle
    const { year, make, model, series, bodyStyle, vehIdentificationNo } =
      vehicle;
    const createVehicleResponse = await makeRequest(() =>
      createVehicle({
        year,
        make,
        model,
        series,
        bodyStyle,
        vehIdentificationNo,
      }),
    );
    // Return early if we were not able to create the vehicle
    if (!createVehicleResponse) return Promise.resolve(undefined);
    const vehiclesAddedManually = [
      ...vehicleContextState.vehiclesAddedManually,
      createVehicleResponse.vehicleId,
    ];
    await handleFetchAllVehicles({
      ...vehicleContextState,
      vehiclesAddedManually,
    });
    return createVehicleResponse;
  };

  const handleUpdateVehicle = async (
    vehicle: Partial<VehicleDescription>,
  ): Promise<VehicleDescription[] | undefined> => {
    const vehicleToUpdate = mapVehicleDescriptionToVehicle(vehicle);

    const updatedState = {
      ...vehicleContextState,
      vehicles: vehicleContextState.vehicles.map((v) => {
        if (v.vehicleId !== vehicle.vehicleId) return v;
        return vehicle;
      }),
    };

    await makeRequest(() => updateVehicle(vehicle.vehicleId!, vehicleToUpdate));

    return handleFetchAllVehicles(updatedState);
  };

  const handleSelectVehicles = async (
    vehicleIds: string[],
  ): Promise<VehicleDescription[] | undefined> => {
    await makeRequest(
      () => selectVehicles(vehicleIds),
      undefined,
      undefined,
      undefined,
      {
        agent: ['BLOCKED_VEHICLE', ...DEFAULT_AGENT_ERROR_CODES],
      },
    );

    const updatedVehicles: VehicleDescription[] =
      vehicleContextState.vehicles.map((vehicle) => ({
        ...vehicle,
        selected: vehicleIds.includes(vehicle.vehicleId!),
      }));

    setVehicleContextState((prevState) => ({
      ...prevState,
      antitheftMetadata,
      vehicles: updatedVehicles,
    }));

    return updatedVehicles;
  };

  const handleUpdateBusinessUsage = async (
    vehicleIds: string[],
  ): Promise<VehicleDescription[] | undefined> => {
    const vehiclesToUpdate: UpdateBusinessUsageRequest = vehicleIds.map(
      (vehicleId: string) => ({
        vehicleId,
        businessUsage: 'MyQuote Business Description',
      }),
    );
    await makeRequest(() => updateBusinessUsage(null, { vehiclesToUpdate }));

    const updatedVehicles: VehicleDescription[] =
      vehicleContextState.vehicles.map((vehicle) => ({
        ...vehicle,
        businessUsage: vehicleIds.includes(vehicle.vehicleId!)
          ? 'MyQuote Business Description'
          : null,
      }));

    setVehicleContextState((prevState) => ({
      ...prevState,
      vehicles: updatedVehicles,
    }));

    return updatedVehicles;
  };

  const handleGetVehiclesById = (
    vehicleIds: string[],
  ): VehicleDescription[] => {
    return vehicleContextState.vehicles.filter((vehicle) =>
      vehicleIds.includes(vehicle.vehicleId!),
    );
  };

  const vehicleIndexes = useMemo(
    () =>
      vehicleContextState.vehicles
        .sort((a, b) => (a.vehicleId ?? '').localeCompare(b.vehicleId ?? ''))
        .reduce<Record<string, number>>(
          (previousValue, currentValue, currentIndex) => ({
            ...previousValue,
            [currentValue.vehicleId ?? '']: currentIndex,
          }),
          {},
        ),
    [vehicleContextState.vehicles],
  );

  const getVehicleIndex = (vehicleId?: string | null) => {
    if (vehicleId == null) {
      return vehicleContextState.vehicles.length;
    }

    return vehicleIndexes[vehicleId];
  };

  const handleUpdateVehiclesVisited = (
    vehicleIds: string[],
    updatedVehicles: VehicleDescription[] = [],
  ) => {
    setVehicleContextState((prevState) => ({
      ...prevState,
      ...(updatedVehicles.length > 0 && { vehicles: updatedVehicles }),
      vehiclesVisited: [...vehicleIds],
    }));
  };

  const handleGetVehicleLienholders = async (
    ownership: VehicleOwnership,
    productCd: ProductCd,
  ) => {
    if (!insuredContextState.lessorLienholderAutoPopulate) return;
    if (
      ownership === VehicleOwnership.Financed &&
      vehicleContextState.vehicleLienholdersMetadata !== undefined
    )
      return;
    if (
      ownership === VehicleOwnership.Leased &&
      vehicleContextState.vehicleLessorMetadata !== undefined
    )
      return;

    const lienholders = await makeRequest(
      () => getVehicleLienholders({ ownership }),
      undefined,
      false,
    );

    setVehicleContextState((prevState) => ({
      ...prevState,
      ...(ownership === VehicleOwnership.Financed
        ? {
            vehicleLienholdersMetadata: lienholders?.filter(
              (lienholder) => lienholder.productCd === productCd,
            ),
          }
        : {
            vehicleLessorMetadata: lienholders?.filter(
              (lienholder) => lienholder.productCd === productCd,
            ),
          }),
    }));
  };

  const handleUpdateVehicleCompanyInfoFlag = (
    vehicleId: string,
    skipped: boolean,
    reset?: boolean,
    thirdPartyAddress?: ThirdPartyAddress,
  ) => {
    if (reset) {
      handleFetchAllVehicles();
    } else {
      setVehicleContextState((prevState) => ({
        ...prevState,
        vehicles: vehicleContextState.vehicles.map((v) => {
          if (v.vehicleId !== vehicleId) return v;
          return {
            ...v,
            completedCompanyInfo: true,
            skippedCompanyInfo: skipped,
            thirdPartyAddress,
          };
        }),
      }));
    }
  };

  const handleUpdateVehicleVin = async (vehicleVinList: any) => {
    const response = await updateVehicleVin(null, { vehicleVinList });
    return response;
  };

  return (
    <VehicleContext.Provider
      value={{
        vehicleContextState,
        fetchAllVehicles: handleFetchAllVehicles,
        createVehicleByVin: handleCreateVehicleByVin,
        createVehicle: handleCreateVehicle,
        updateVehicle: handleUpdateVehicle,
        selectVehicles: handleSelectVehicles,
        getVehiclesById: handleGetVehiclesById,
        getVehicleIndex,
        updateVehiclesVisited: handleUpdateVehiclesVisited,
        updateVehicleCompanyInfoFlag: handleUpdateVehicleCompanyInfoFlag,
        fetchAllVehiclesAddress: handleFetchAllVehiclesAddress,
        updateBusinessUsage: handleUpdateBusinessUsage,
        updateVehicleVin: handleUpdateVehicleVin,
        getVehicleMileage: handleGetVehicleMileage,
        getVehicleLienholders: handleGetVehicleLienholders,
      }}
    >
      {props.children}
    </VehicleContext.Provider>
  );
};

export default VehicleContextProvider;
type VehicleResponseItem = GetVehiclesResponse[number];
type VehicleResponseItemWithAddress = Omit<VehicleResponseItem, 'vehicle'> & {
  vehicle: Partial<VehiclePrimaryInfo> &
    Partial<VehicleAdditionalInfo> &
    Partial<Vehicle>;
};
const mapVehicleResponseToVehicleDescription = (
  vehicle: VehicleResponseItemWithAddress,
): VehicleDescription => ({
  source: vehicle.source,
  selected: vehicle.selected,
  vehicleId: vehicle.vehicleId,
  vin: vehicle.vehicle.vehIdentificationNo,
  year: vehicle.vehicle.year,
  make: vehicle.vehicle.make,
  model: vehicle.vehicle.model,
  series: vehicle.vehicle.series,
  bodyStyle: vehicle.vehicle.bodyStyle,
  usageType: vehicle.vehicle.vehicleUsageCd,
  ownership: vehicle.vehicle.ownershipTypeCd,
  antiTheft: vehicle.vehicle.antiTheft,
  antiLockBrakes: vehicle.vehicle.antiLockBrakes,
  driveLessThan1000: vehicle.vehicle.driveLessThan1000,
  daytimeRunningLights: vehicle.vehicle.daytimeRunningLights,
  completed: !!(
    vehicle.vehicle.vehicleUsageCd &&
    vehicle.vehicle.ownershipTypeCd &&
    (!vehicle.vehicle.rideshare ||
      (vehicle.vehicle.avgDaysRS && vehicle.vehicle.avgRidesRS))
  ),
  completedCompanyInfo: false,
  skippedCompanyInfo:
    !vehicle.vehicle.thirdPartyAddress &&
    vehicle.vehicle.ownershipTypeCd !== 'OWN',
  isRideshare: vehicle.vehicle.rideshare,
  rideshareDaysPerWeek: vehicle.vehicle.avgDaysRS,
  rideshareRidesPerWeek: vehicle.vehicle.avgRidesRS,
  milesOneWay: vehicle.vehicle.milesOneWay,
  odometerReading: vehicle.vehicle.odometerReading,
  annualMiles: vehicle.vehicle.annualMiles,
  thirdPartyAddress: vehicle.vehicle.thirdPartyAddress,
  addedUsingVin: vehicle.vehicle.addedUsingVIN,
});

const mapVehicleDescriptionToVehicle = (
  vehicle: VehicleDescription,
): Partial<VehiclePrimaryInfo & VehicleAdditionalInfo> => ({
  vehIdentificationNo: vehicle.vin,
  year: vehicle.year,
  make: vehicle.make,
  model: vehicle.model,
  series: vehicle.series,
  bodyStyle: vehicle.bodyStyle,
  vehicleUsageCd: vehicle.usageType,
  milesOneWay: vehicle.milesOneWay,
  odometerReading: vehicle.odometerReading,
  annualMiles: vehicle.annualMiles,
  antiTheft: vehicle.antiTheft,
  daytimeRunningLights: vehicle.daytimeRunningLights,
  antiLockBrakes: vehicle.antiLockBrakes,
  driveLessThan1000: vehicle.driveLessThan1000,
  ownershipTypeCd: vehicle.ownership,
  rideshare: vehicle.isRideshare,
  avgDaysRS: vehicle.rideshareDaysPerWeek,
  avgRidesRS: vehicle.rideshareRidesPerWeek,
});
