import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { QuoteType } from '@aaa-ncnu-ie/ez-quote-session-types';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { getCookie } from 'helpers/cookies';
import { OnHydrated } from 'helpers/form';
import { getNameByPath, Page, pages } from 'helpers/pages';
import { useCountyTownship } from 'hooks/useCountyTownship';
import useSegment from 'hooks/useSegment';
import { useValidation } from 'hooks/useValidation';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { useBackForwardHandler } from '../hooks/useBackForwardHandler';
import { FormValues } from './forms/formTypes';
import IdleTimer from './IdleTimer/IdleTimerDisplay';

export const initialValues: FormValues = {
  retrieveQuote: {
    dateOfBirth: null,
    email: '',
    zipcode: '',
  },
  zipcode: '',
  product: undefined,
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  maritalStatus: null,
  occupation: null,
  coapplicant: false,
  address: '',
  addressZip: '',
  apartment: '',
  previousAddress: '',
  previousApartment: '',
  vin: '',
  year: '',
  bypassAddress: {
    current: {
      city: '',
      streetAddress: '',
      state: null,
      zip: '',
      county: '',
    },
    previous: {
      city: '',
      streetAddress: '',
      state: null,
      zip: '',
      county: '',
    },
    garagingAddress: {
      city: '',
      streetAddress: '',
      state: null,
      zip: '',
      county: '',
    },
    mailingAddress: {
      city: '',
      streetAddress: '',
      state: null,
      zip: '',
      county: '',
    },
    thirdPartyAddress: {
      city: '',
      streetAddress: '',
      state: null,
      zip: '',
      county: '',
    },
  },
  garagingAddress: '',
  garagingApartment: '',
  mailingAddress: '',
  mailingApartment: '',
  thirdPartyAddress: '',
  thirdPartyApartment: '',
  lineHolderName: '',
  lineHolderNameOther: '',
  discountOption: undefined,
  propertyBundleOption: undefined,
  propertyClaimsOption: undefined,
  residenceType: undefined,
  fireAlarmType: undefined,
  theftAlarmType: undefined,
  isSprinkler: undefined,
  make: null, // All the TypeAhead / select fields must be initialized to null or we'll get controlled/uncontrolled switching errors
  model: null,
  vehicleIds: [],
  vehicleOwnership: undefined,
  vehicleUsageType: undefined,
  isRideshare: undefined,
  daytimeRunningLights: undefined,
  antiLockBrakes: undefined,
  driveLessThan1000: undefined,
  annualMiles: '',
  milesOneWay: '',
  odometerReading: '',
  email: '',
  cellNumber: '',
  driverIds: [],
  ageFirstLicensed: '',
  birthDate: null,
  relationship: null,
  gender: null,
  isPrimaryResidence: '',
  occupancyType: '',
  noOfClaims: undefined,
  squareFootage: '',
  yearBuilt: '',
  qualityGrade: undefined,
  rideshareDaysPerWeek: '',
  rideshareRidesPerWeek: '',
  isInsuredLastSixMonths: null,
  currentCarrierCompany: '',
  yearsWithPriorInsurer: '',
  priorBiLimit: '',
  incidents: [],
  hasIncidents: undefined,
  studentDiscounts: [],
  smartDriverDiscounts: [],
  defensiveDriverDiscounts: [],
  travelinkDiscounts: [],
  isLongtermDwelling: null,
  shareTelematicsDrivingData: null,
  isRetirementCommunity: null,
  numberResidents: undefined,
  driverAssignment: [],
  incidentDriver: null,
  incidentType: null,
  incidentYear: '',
  antiTheft: undefined,
  primaryInsurer: '',
  healthInsurerName: '',
  healthInsurerPolicyNumber: '',
  maskedHealthInsurerPolicyNumber: '',
  isHealthInsurance: true,
  medicalExpLimit: '',
  isGaragingAddress: null,
  isMailingAddress: null,
  isMailingPOBox: null,
  isPOBox: null,
  poBoxAddress: {
    pobox: '',
    city: '',
    state: null,
    zip: '',
  },
  poBoxMailingAddress: {
    pobox: '',
    city: '',
    state: null,
    zip: '',
  },
  effectiveDate: null,
  commercialVehicleIds: [],
  paperlessEnrolled: undefined,
  textEnrolled: undefined,
  paperlessEmail: '',
  textAlertsPhone: '',
  evalueEnrolled: null,
  vehiclevin: [],
  smartTrek: undefined,
  noUSALicense: null,
  driverLicense: '',
  maskedDriverLicense: '',
  driverEmail: '',
  driverCellNumber: '',
  driverLicenseState: null,
  esignatureAgree: null,
  paymentPlans: undefined,
  paymentMethod: undefined,
  additionalInsured: {
    firstName: '',
    lastName: '',
    birthDate: null,
  },
  landlordDetails: {
    additionalInterestName: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateProvCd: '',
      postalCode: '',
      county: '',
      countyName: '',
      township: '',
      addressValidated: undefined,
      isPoBox: undefined,
    },
  },
  constructionType: undefined,
  noFamilyUnits: undefined,
  dwellingDetails: undefined,
  numberOfStories: undefined,
  garageType: undefined,
  noFireplaces: undefined,
  foundationType: undefined,
  exteriorWallFinish: undefined,
  quoteType: QuoteType.MONOLINE,
  isMembershipAutoRenew: undefined,
  memberIds: [],
};

export const Hydrator = () => {
  const [hydratedValues, setHydratedValues] = useState<Partial<FormValues>>({});
  const { setValues, values } = useFormikContext<Partial<FormValues>>();

  useEffect(() => {
    OnHydrated.subscribe((newValues) => {
      setHydratedValues({ ...hydratedValues, ...newValues });
    });
    return () => {
      OnHydrated.callbacks = [];
    };
  }, []);

  useEffect(() => {
    setValues({ ...values, ...hydratedValues });
  }, [hydratedValues]);

  return null;
};

const Hooks: React.FC = () => {
  useCountyTownship();
  useBackForwardHandler();
  return null;
};

const lastViewedPagesToIgnore = ['Redirect', 'ThankYou', 'Thinking'];
const QuoteRouter = () => {
  const { pathname } = useLocation();
  const pageName = getNameByPath(pathname);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const validationSchema = useValidation(
    pageName,
    formikRef.current?.values?.product,
  );
  const { initialize, page } = useSegment();
  const segmentIdSet = getCookie('segmentIdSet');
  const isViewSaveAndRetrieveQuoteRestricted =
    !getCookie('featureFlags')?.RETRIEVE_QUOTE_FEATURE;

  const orignalPageState = [...pages]; //ReadOnly arrays are unmutable
  const [nonRestrictedPages, updateNonRestrictedPages] =
    useState(orignalPageState);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (isViewSaveAndRetrieveQuoteRestricted) {
      //This is now the spot to add logic and filter any restricted pages
      const currentNonRestrictedPages = nonRestrictedPages.filter(
        (p) => p.name !== 'ViewSavedQuote' && p.name !== 'RetrieveQuote',
      );
      updateNonRestrictedPages(currentNonRestrictedPages);
    } else {
      updateNonRestrictedPages(orignalPageState);
    }
  }, [isViewSaveAndRetrieveQuoteRestricted]);

  useEffect(() => {
    const _pageName = pageName.replace('BundleQuestion', 'MultiPolicyDiscount');
    Boolean(segmentIdSet) &&
      !lastViewedPagesToIgnore.includes(_pageName) &&
      page(_pageName);
    document
      .querySelectorAll('img')
      .forEach((img) => img.setAttribute('aria-hidden', 'true'));
  }, [pageName, segmentIdSet]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues) => {
        console.log('submit form', values);
      }}
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {() => (
        <>
          <Hooks />
          <Hydrator />
          <NavBar />
          {getCookie('featureFlags')?.IDLE_TIMER_FEATURE && <IdleTimer />}
          <Switch>
            {nonRestrictedPages.map((page: Page) => (
              <Route
                exact={page.exact === undefined ? true : page.exact}
                key={page.key}
                path={page.path}
                component={page.component}
              />
            ))}
            <Redirect to="/" />
          </Switch>
          <Footer />
        </>
      )}
    </Formik>
  );
};

export default QuoteRouter;
