import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useGetProperty } from 'api/queries/property';
import { PolicyType } from 'api/schema/insured.schema';
import { getCookie } from 'helpers/cookies';
import { useDeviceTypes } from 'helpers/devices';
import {
  getIndexByName,
  getIndexByPath,
  getNameByKey,
  getNameByPath,
  PageName,
  Tabs,
} from 'helpers/pages';
import { getAttribute } from 'helpers/testing';
import { MenuData } from 'hooks/useMenuData';
import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';
import { QuoteContext } from 'components/contexts/QuoteContext';
import { getIsProperty } from '../helpers/policy-and-residence';
import Button from './common/Button';
import { PhoneNumberLink } from './common/PhoneNumberLink';
import { ClubContext } from './contexts/ClubContext';
import { InsuredContext } from './contexts/InsuredContext';
import { getPathByTabIndex, useAccessLink } from './NavBar';

const useMenuStyles = makeStyles<{ isDesktop: boolean }>()(
  ({ breakpoints }, { isDesktop }) => ({
    paper: {
      pointerEvents: 'auto',
      width: isDesktop ? '17.5%' : '192px',
      boxShadow: !isDesktop ? 'none' : `0 8px 16px ${colors.grey90}29`,
      borderRadius: '0 0 4px 4px',
      left: !isDesktop ? '0!important' : undefined,
    },
    root: {
      pointerEvents: 'none',
      '& .MuiListItemText-root': {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
      },
    },
    phone: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      margin: '12px 16px 18px 16px',
      lineHeight: '16.8px',
      fontFamily: 'Cabin',
      '& span': {
        color: colors.primaryBlue50,
        fontFamily: 'Cabin',
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: 'bold',
      '&.selected': {
        color: colors.primaryBlue50,
      },
      margin: '0',
    },
    menuList: {
      backgroundColor: colors.white,
      padding: isDesktop ? '8px 0' : 0,
    },
    menuItemWrap: {
      boxShadow: '0px 4px 8px rgba(25, 30, 43, 0.08)',
      margin: '0px 8px 0px 0px',
      borderRadius: '4px',
      padding: '16px 0px 14px 0px',
    },
    menuItemWrapUl: {
      paddingLeft: '0px',
    },
    menuItem: {
      margin: 0,
      padding: isDesktop ? '10px 16px' : '4px 0px 4px 16px',
      color: colors.grey100,
      opacity: 1,
      maxWidth: '100%',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      '&.Mui-disabled': {
        color: colors.grey60,
        opacity: 1,
        '& .MuiListItemText-primary': {
          color: colors.grey60,
        },
        '& .no-link': {
          color: colors.blue50,
        },
      },
      '& .no-primary': {
        margin: '0px',
        paddingLeft: isDesktop ? '8px' : '4px',
      },
      '&:last-child': {
        marginBottom: isDesktop ? '8px' : '0px',
      },
    },
    section: !isDesktop
      ? {
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colors.white,
          margin: '0 4px 4px 0',
          borderRadius: '4px',
          boxShadow: `0 8px 16px ${colors.grey90}29`,
        }
      : {},
    primary: {
      color: colors.blue50,
      fontSize: isDesktop ? '18px' : '16px',
      lineHeight: '25.2px',
      fontWeight: 700,
      margin: `0`,
      paddingRight: `5px`,
      fontFamily: 'Raleway',
    },
    secondary: {
      fontSize: isDesktop ? '18px' : '16px',
      lineHeight: '25px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: colors.grey60,
      margin: isDesktop ? '16px 8px 0' : '0 4px',
      fontFamily: 'Raleway',
    },
    saveQuoteButton: {
      [breakpoints.up('desktop')]: {
        margin: '0 35px',
      },
      [breakpoints.down('desktop')]: {
        width: '100%',
        margin: '12px auto',
      },
    },
    saveQuoteContainer: {
      [breakpoints.up('desktop')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        opacity: 1,
      },
    },
  }),
);

export const NavigationMenu = (
  props: React.ComponentProps<typeof Menu> & {
    hoverTab: Tabs;
    data: MenuData;
    currentTabIndex: number | boolean;
    onMouseLeave?: () => void;
    isPurchaseMenuEnabled?: boolean;
    isReviewCoveragesEnabled?: boolean;
  },
) => {
  const id = 'navigation-menu';
  const { isDesktop } = useDeviceTypes();
  const { classes } = useMenuStyles({ isDesktop });
  const {
    hoverTab,
    data,
    currentTabIndex,
    isPurchaseMenuEnabled,
    isReviewCoveragesEnabled,
    onMouseLeave = () => undefined,
    onClose,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { quoteElementState, setQuoteElementState, quoteState } =
    useContext(QuoteContext);
  const [{ bindEnabled: isBindEnabled, insured }] = useContext(InsuredContext);
  const { clubState } = useContext(ClubContext);
  const showDwellingDetailsPageOnQuoteFlow =
    !!clubState?.featureFlags?.showDwellingDetailsPageOnQuoteFlow;

  const accessLink = useAccessLink();
  const location = useLocation();
  const {
    incompleteVehicleCompanyInfo,
    businessVehiclesList,
    vinVehiclesList,
    incompleteDriverDlInfo,
  } = data;
  const isAboutYouTabHover = hoverTab === Tabs.AboutYou;
  const isWhatsCoveredTabHover = hoverTab === Tabs.WhatsCovered;
  const isPurchaseTabHover = hoverTab === Tabs.Purchase;
  const isHO3Bind =
    quoteState?.quoteInfo?.baseQuote === PolicyType.HOMEOWNERS && isBindEnabled;
  const showSpouseInfo: boolean =
    (insured?.maritalStatus && ['M', 'C'].includes(insured?.maritalStatus)) ||
    false;

  const hasAboutYouData = !!data.about;
  const visitedPages = data.visitedTabs;

  const hasAutoCoverageData =
    data.coverage?.auto &&
    data.coverage.auto.vehicles &&
    data.coverage.auto.drivers;
  const hasPropertyCoverageData =
    !!data.coverage?.property && data.coverage.property.address;

  const getIsTabSelected = (index: number) => currentTabIndex === index;

  const shouldShowPNIInfo =
    visitedPages.includes(Tabs.WhatsCovered) &&
    hasAboutYouData &&
    (!isDesktop || isAboutYouTabHover);
  const shouldShowAutoCoverageItems =
    visitedPages.includes(Tabs.Discounts) &&
    hasAutoCoverageData &&
    (!isDesktop || isWhatsCoveredTabHover);
  const shouldShowPropertyCoverageItems =
    visitedPages.includes(Tabs.Discounts) &&
    hasPropertyCoverageData &&
    (!isDesktop || isWhatsCoveredTabHover);
  const shouldShowPurchaseItems = Boolean(
    visitedPages.includes(Tabs.Purchase) &&
      quoteState.effectiveDate &&
      isPurchaseMenuEnabled &&
      (!isDesktop || isPurchaseTabHover),
  );
  const shouldShowVehicles =
    shouldShowPurchaseItems &&
    (businessVehiclesList?.length ||
      incompleteVehicleCompanyInfo?.length ||
      vinVehiclesList?.length);
  const shouldShowDriversDl =
    shouldShowPurchaseItems && incompleteDriverDlInfo?.length;

  const isProperty = getIsProperty(quoteState?.quoteInfo?.baseQuote);
  const { data: propertyDetailsData } = useGetProperty({
    enabled: isProperty && shouldShowPurchaseItems,
  });
  const currentYear = new Date().getFullYear();
  const showRenovationDetails =
    shouldShowPurchaseItems &&
    propertyDetailsData?.yearBuilt &&
    currentYear - Number(propertyDetailsData.yearBuilt) > 10;

  const aboutYouTabs = shouldShowPNIInfo ? [0, 1] : [];
  let whatsCoveredTabs = shouldShowAutoCoverageItems ? [0, 1] : [];
  whatsCoveredTabs = shouldShowPropertyCoverageItems
    ? [...whatsCoveredTabs, 2]
    : whatsCoveredTabs;
  let purchaseTabs = shouldShowPurchaseItems
    ? isHO3Bind
      ? [
          0,
          1,
          ...(showSpouseInfo ? [2] : []),
          3,
          4,
          5,
          ...(showRenovationDetails ? [6] : []),
          7,
          8,
          9,
        ]
      : [0, 1, 2, 3]
    : [];
  purchaseTabs = shouldShowVehicles ? [...purchaseTabs, 4] : purchaseTabs;
  purchaseTabs = shouldShowDriversDl ? [...purchaseTabs, 5] : purchaseTabs;
  purchaseTabs =
    shouldShowPurchaseItems && isReviewCoveragesEnabled && !isHO3Bind
      ? [...purchaseTabs, 6]
      : purchaseTabs;
  purchaseTabs = purchaseTabs.sort((a, b) => a - b);

  const getDynamicVehiclePage = (): { name: PageName | ''; param: string } => {
    if (businessVehiclesList?.length) {
      return { name: getNameByKey('commercialPurposes'), param: '' };
    } else if (incompleteVehicleCompanyInfo?.length) {
      return {
        name: getNameByKey('companyInfo'),
        param: `?vehicleId=${incompleteVehicleCompanyInfo[0]?.vehicleId}`,
      };
    } else if (vinVehiclesList?.length) {
      return { name: getNameByKey('vinCollectPageForm'), param: '' };
    }
    return {
      name: getNameByKey('confirmDriverLicense'),
      param: incompleteDriverDlInfo?.length
        ? `?driverId=${incompleteDriverDlInfo[0]?.driverId}`
        : '',
    };
  };

  const dyanimcVehiclePage = getDynamicVehiclePage();
  const structure = [
    {
      section: 'tell-us-about-you-menu-section',
      heading: {
        tab: 0,
        id: 'primary-heading',
        link: getNameByKey('tellUsAboutYou'),
        text: t('navigation.aboutYou'),
      },
      items: [
        {
          id: 'primary',
          link: getNameByKey('tellUsAboutYou'),
          linkParams: '',
          primary: t('navigation.menu.primary'),
          secondary: data.about?.primary,
        },
        {
          id: 'address',
          link: getNameByKey('address'),
          linkParams: '',
          primary: t('navigation.menu.address'),
          secondary: data.about?.address,
        },
      ],
    },
    {
      section: 'whats-covered-menu-section',
      heading: {
        tab: 1,
        id: 'whats-covered-heading',
        link: getNameByPath(getPathByTabIndex(1, data.product)),
        text: t('navigation.whatsCovered'),
      },
      items: [
        {
          id: 'vehicles',
          link: getNameByKey('selectVehicles'),
          linkParams: '',
          primary: t('navigation.menu.vehicles'),
          secondary: data.coverage?.auto?.vehicles,
        },
        {
          id: 'drivers',
          link: getNameByKey('selectDrivers'),
          linkParams: '',
          primary: t('navigation.menu.drivers'),
          secondary: data.coverage?.auto?.drivers,
        },
        {
          id: 'property',
          link: getNameByKey('dwellingUsage'),
          linkParams: '',
          primary: t('navigation.menu.property'),
          secondary: data.coverage?.property?.address,
        },
      ],
    },
    {
      section: 'discounts-menu-section',
      heading: {
        tab: 2,
        id: 'discounts-heading',
        link: getNameByKey('discountTransition'),
        text: t('navigation.discounts'),
      },
      items: [],
    },
    {
      section: 'quote-menu-section',
      heading: {
        tab: 3,
        id: 'quote-heading',
        link: getNameByKey('quote'),
        text: t('navigation.quote'),
      },
      items: [],
    },
    {
      section: 'purchase-menu-section',
      heading: {
        tab: 4,
        id: 'purchase-heading',
        link: isBindEnabled
          ? getNameByKey('effectiveDate')
          : getNameByKey('confirmation'),
        text: t('navigation.purchase'),
      },
      items: [
        {
          id: 'additionalinfo',
          link: '',
          linkParams: '',
          primary: t('navigation.menu.additionalinfo'),
          secondary: '',
        },
        ...(isHO3Bind
          ? [
              {
                id: 'effectiveDate',
                link: getNameByKey('effectiveDate'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.policyDate'),
              },
              {
                id: 'additionalInsured',
                link: getNameByKey('additionalInsured'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.additionalInsured'),
              },
              {
                id: 'paperlessConsent',
                link: getNameByKey('paperlessConsent'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.paperlessPreference'),
              },
              {
                id: 'mailingAddress',
                link: getNameByKey('mailingAddressConfirmation'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.address'),
              },
              ...(!showDwellingDetailsPageOnQuoteFlow
                ? [
                    {
                      id: 'dwellingDetails',
                      link: getNameByKey('dwellingDetailsPage'),
                      linkParams: '',
                      primary: '',
                      secondary: t('navigation.menu.dwellingDetails'),
                    },
                  ]
                : []),
              {
                id: 'propertyRenovations',
                link: getNameByKey('propertyRenovations'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.propertyRenovations'),
              },
              {
                id: 'mortgagePageForm',
                link: getNameByKey('mortgagePageForm'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.mortgagePageForm'),
              },
              {
                id: 'propertyOptionalAmenities',
                link: getNameByKey('propertyOptionalAmenities'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.propertyOptionalAmenities'),
              },
            ]
          : [
              {
                id: 'effectiveDate',
                link: getNameByKey('effectiveDate'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.policyDate'),
              },
              {
                id: 'preferences',
                link: getNameByKey('paperlessConsent'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.paperlessPreference'),
              },
              {
                id: 'mailingAddress',
                link: getNameByKey('mailingAddressConfirmation'),
                linkParams: '',
                primary: '',
                secondary: t('navigation.menu.address'),
              },
              {
                id: 'vehicles2',
                link: dyanimcVehiclePage.name,
                linkParams: dyanimcVehiclePage.param,
                primary: '',
                secondary: t('navigation.menu.vehicles'),
              },
              {
                id: 'drivers2',
                link: getNameByKey('confirmDriverLicense'),
                linkParams: incompleteDriverDlInfo?.length
                  ? `?driverId=${incompleteDriverDlInfo[0]?.driverId}`
                  : '',
                primary: '',
                secondary: t('navigation.menu.drivers'),
              },
            ]),
        {
          id: 'reviewCoveragesandPurchase',
          link: getNameByKey('quoteBind'),
          linkParams: '',
          primary: t('navigation.menu.reviewCoveragesandPurchase'),
          secondary: '',
        },
      ],
    },
  ] as const;

  const generateMenuHeading = (index: number) => {
    const heading = structure[index]?.heading;
    if (!heading) return null;
    return (
      <MenuItem
        aria-label="Menu Heading"
        aria-labelledby={heading.id}
        aria-selected={getIsTabSelected(heading.tab)}
        disabled={
          !data.visitedTabs.includes(heading.tab) ||
          Number(currentTabIndex) <= Number(heading.tab)
        }
        classes={{ root: classes.menuItem }}
        key={heading.id}
        {...getAttribute(id, heading.id)}
        onClick={() => accessLink(heading.link)}
        tabIndex={0}
        role="menuitem"
      >
        <ListItemText
          classes={{
            primary: [
              classes.title,
              (getIsTabSelected(0) || '') && 'selected',
            ].join(' '),
          }}
          id={heading.id}
          primary={heading.text}
        />
      </MenuItem>
    );
  };

  const generateMenuItem = (category: number, index: number) => {
    const item = structure[category]?.items[index];
    if (!item) return null;
    if (!item.link)
      return (
        <MenuItem
          aria-label="Menu Data Item"
          aria-labelledby={item.id}
          classes={{ root: `${classes.menuItem} no-link` }}
          key={item.id}
          {...getAttribute(id, item.id)}
          disabled={true}
          tabIndex={0}
          role="menuitem"
        >
          <ListItemText
            classes={{
              primary: `${classes.primary} no-link`,
            }}
            id={item.id}
            primary={item.primary}
          />
        </MenuItem>
      );
    return (
      <MenuItem
        aria-label="Menu Data Item"
        aria-labelledby={item.id}
        classes={{ root: classes.menuItem }}
        key={item.id}
        {...getAttribute(id, item.id)}
        onClick={() => accessLink(item.link, item.linkParams)}
        disabled={
          getIndexByPath(location.pathname) <
          getIndexByName(item.link as PageName)
        }
        tabIndex={0}
        role="menuitem"
      >
        <ListItemText
          classes={{
            primary: classes.primary,
            secondary: `${classes.secondary} ${!item.primary && 'no-primary'}`,
          }}
          id={item.id}
          primary={item.primary}
          secondary={item.secondary}
        />
      </MenuItem>
    );
  };

  const generateMenu = (category: number, menuItems: number[]) => {
    return !isDesktop ? (
      <li className={classes.menuItemWrap}>
        <ul className={classes.menuItemWrapUl}>
          {' '}
          {generateMenuHeading(category)}
          {menuItems.map((menuItem) => generateMenuItem(category, menuItem))}
        </ul>
      </li>
    ) : (
      menuItems.map((menuItem) => generateMenuItem(category, menuItem))
    );
  };

  return (
    <Menu
      autoFocus
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disableScrollLock
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      MenuListProps={{
        className: classes.menuList,
        onMouseLeave,
      }}
      PopoverClasses={{
        root: classes.root,
        paper: classes.paper,
      }}
      id={id}
      {...rest}
      onKeyDown={(e) => {
        e.key === 'Escape' && onClose && onClose({}, 'escapeKeyDown');
      }}
      tabIndex={0}
    >
      {!isDesktop && data.phone && (
        <li>
          <Typography className={classes.phone}>
            {t('navigation.needHelp')}
            <PhoneNumberLink formattedPhoneNumber={data.phone} />
          </Typography>
        </li>
      )}

      {generateMenu(Tabs.AboutYou, aboutYouTabs)}
      {generateMenu(Tabs.WhatsCovered, whatsCoveredTabs)}
      {generateMenu(Tabs.Discounts, [])}
      {generateMenu(Tabs.Quote, [])}
      {shouldShowPurchaseItems && generateMenu(Tabs.Purchase, purchaseTabs)}

      {getCookie('featureFlags')?.SAVE_QUOTE_FEATURE &&
        !isDesktop &&
        [Tabs.WhatsCovered, Tabs.Discounts].includes(
          currentTabIndex as Tabs,
        ) && (
          <Box className={classes.saveQuoteContainer}>
            <Button
              fullWidth={false}
              color="secondary"
              csaaType="secondary"
              variant={isDesktop ? 'contained' : 'text'}
              className={classes.saveQuoteButton}
              onClick={() => {
                onClose && onClose({}, 'backdropClick');
                setQuoteElementState({
                  ...quoteElementState,
                  saveQuoteModalOpen: true,
                });
              }}
            >
              {t('savequotemodal.button')}
            </Button>
          </Box>
        )}
    </Menu>
  );
};
