import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useSegment from 'hooks/useSegment';
import { makeStyles } from 'tss-react/mui';
import { ClubContext } from 'components/contexts/ClubContext';
import { InsuredContext } from 'components/contexts/InsuredContext';
import { QuoteContext } from 'components/contexts/QuoteContext';
import { getNameByPath } from '../helpers/pages';
import { colors } from '../styles/theme';
import { PhoneNumberLink } from './common/PhoneNumberLink';

const footerHeight = '144px';

type stylePropTypes = {
  marginBottom: string;
};

const useStyles = makeStyles<stylePropTypes>()(
  ({ breakpoints }: Theme, { marginBottom }) => ({
    root: {
      marginBottom,
      background: colors.white,
      height: footerHeight,
      maxHeight: footerHeight,
      position: 'relative',
      padding: '37px 53px',
      boxSizing: 'border-box',
      [breakpoints.only('mobile')]: {
        height: '210px',
        maxHeight: '210px',
        padding: '24px',
      },
    },
    copyrightContainer: {
      fontFamily: 'Cabin',
      fontSize: '12px',
      color: colors.grey60,
      marginTop: '25px',
      textAlign: 'center',
    },
    linksContainer: {
      textAlign: 'center',
      maxWidth: '700px',
      margin: '0 auto',
      [breakpoints.only('mobile')]: {
        flexDirection: 'column',
        paddingTop: '24px',
        margin: 0,
      },
      [breakpoints.up('tablet')]: {
        flexDirection: 'row',
      },
    },

    phoneLink: {
      color: colors.linkBlue,
      textDecoration: 'none',
    },
    linkSeparator: {
      color: colors.black,
      fontSize: '18px',
      fontWeight: 700,
    },
    link: {
      cursor: 'pointer',
      '&:not(:visited)': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineOffset: '5px',
        outlineColor: colors.linkBlue,
      },
      fontSize: '18px',
      fontWeight: 700,
      color: '#115DC4',
      [breakpoints.only('mobile')]: {
        fontSize: '16px',
      },
    },
    linkRe: {
      cursor: 'pointer',
      '&:not(:visited)': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outlineOffset: '5px',
        outlineColor: colors.linkBlue,
      },
      fontWeight: 700,
      color: '#115DC4',
    },
    agentNumber: {
      fontSize: '18px',
      fontWeight: 700,
      color: colors.grey60,
      [breakpoints.only('mobile')]: {
        fontSize: '16px',
      },
    },
    phoneContainer: {
      textAlign: 'center',
      height: '100%',
      [breakpoints.up('tablet')]: {
        alignSelf: 'flex-start',
      },
      [breakpoints.only('tablet')]: {
        paddingBottom: '8px',
      },
      [breakpoints.only('mobile')]: {
        textAlign: 'left',
      },
    },
  }),
);

const Footer = () => {
  const location = useLocation();
  const { quoteElementState } = useContext(QuoteContext);
  const [{ chatEnabled }] = useContext(InsuredContext);

  const getMarginBottom = () => {
    const isOnQuotePage = getNameByPath(location.pathname) === 'Quote';
    if (isOnQuotePage) return quoteElementState.cartOpen ? '315px' : '125px';
    return '';
  };

  const {
    root,
    phoneContainer,
    agentNumber,
    linksContainer,
    link,
    linkRe,
    linkSeparator,
    copyrightContainer,
  } = useStyles({ marginBottom: getMarginBottom() }).classes;
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  useHistory();
  const { clubState } = useContext(ClubContext);
  const isTabletOrDesktop = useMediaQuery(breakpoints.up('tablet'));
  useMediaQuery(breakpoints.only('mobile'));
  const { track } = useSegment();
  const isPurchaseConfirmation =
    getNameByPath(location.pathname) === 'PurchaseConfirmation';

  const handleLinkClick = (linkName: string): void => {
    track('Link Accessed', {
      link: linkName,
      link_placement: 'footer',
      link_destination: clubState.officeLink,
      eventType: 'Link Accessed',
    });
  };

  return (
    <div className={root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          mobile={12}
          tablet={12}
          desktop={3}
          className={phoneContainer}
        >
          <Typography className={agentNumber} data-testid="footer-agent-number">
            {(clubState.supportPhoneNo || isPurchaseConfirmation) && (
              <>
                {t('footer.help')} <PhoneNumberLink />{' '}
                {chatEnabled && t('footer.twentyfourseven')}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item mobile={12} tablet={12} desktop={6}>
          <Grid
            className={linksContainer}
            container
            alignItems={isTabletOrDesktop ? 'center' : 'flex-start'}
            justifyContent="space-between"
          >
            <Link
              className={link}
              href={clubState.officeLink}
              rel="noopener noreferrer"
              target="_blank"
              data-testid="footer-agent-link"
              onClick={() => handleLinkClick('Find An Agent Link')}
              onKeyDown={(e: React.KeyboardEvent) =>
                e.key === 'Enter' && handleLinkClick('Find An Agent Link')
              }
              underline="hover"
            >
              <Typography className={link}>
                {t('footer.links.agent')}
              </Typography>
            </Link>
            {isTabletOrDesktop && <span className={linkSeparator}>|</span>}
            <Link
              className={link}
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/footer-nav/terms-of-use.html"
              rel="noopener noreferrer"
              target="_blank"
              data-testid="footer-tac-link"
              onClick={() => handleLinkClick('Terms And Conditions Link')}
              onKeyDown={(e: React.KeyboardEvent) =>
                e.key === 'Enter' &&
                handleLinkClick('Terms And Conditions Link')
              }
              underline="hover"
            >
              <Typography className={link}>
                {t('footer.links.termsandconditions')}
              </Typography>
            </Link>
            {isTabletOrDesktop && <span className={linkSeparator}>|</span>}
            <Link
              className={link}
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/footer-nav/privacy-policy.html"
              rel="noopener noreferrer"
              target="_blank"
              data-testid="footer-pp-link"
              onClick={() => handleLinkClick('Privacy Policy Link')}
              onKeyDown={(e: React.KeyboardEvent) =>
                e.key === 'Enter' && handleLinkClick('Privacy Policy Link')
              }
              underline="hover"
            >
              <Typography className={link}>
                {t('footer.links.privacypolicy')}
              </Typography>
            </Link>
            {isTabletOrDesktop && <span className={linkSeparator}>|</span>}
            <Link
              className={link}
              href="https://csaa-insurance.aaa.com/content/aaa-ie/b2c/en/footer-nav/accessibility-statement.html"
              rel="noopener noreferrer"
              target="_blank"
              data-testid="footer-pp-link"
              onClick={() => handleLinkClick('Accessibility Statement Link')}
              onKeyDown={(e: React.KeyboardEvent) =>
                e.key === 'Enter' &&
                handleLinkClick('Accessibility Statement Link')
              }
              underline="hover"
            >
              <Typography className={link}>
                {t('footer.links.accessibilityStatement')}
              </Typography>
            </Link>
          </Grid>
          <p className={copyrightContainer} data-testid="footer-copyright">
            {`${t('footer.copyright')}`} <br />
            {`© ${new Date().getFullYear()} CSAA Insurance Group`}
          </p>
          <p
            className={copyrightContainer}
            data-testid="footer-recaptcha-branding"
          >
            This site is protected by reCAPTCHA and the Google{' '}
            <a className={linkRe} href="https://policies.google.com/privacy">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a className={linkRe} href="https://policies.google.com/terms">
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </Grid>

        <Grid
          item
          mobile={12}
          tablet={12}
          desktop={3}
          style={{ visibility: 'hidden' }}
        />
      </Grid>
    </div>
  );
};

export default Footer;
